import React from 'react'
import { Link } from 'gatsby'
import { Row, Col } from 'reactstrap'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import { SEO } from '../components/atoms/seo'

const IndexPage = ({ location }) => {
  const { site, allFile } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          subtitle
          copyrightDate
          authorLink
          contactLocation
          description
          menu {
            path
            title
          }
        }
      }
      allFile(filter: { name: { in: "lou-blue-sq-chest" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: AUTO
                height: 600
                width: 600
              )
              resize(
                cropFocus: ATTENTION
                grayscale: true
                height: 200
                width: 200
              ) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
            }
          }
        }
      }
    }
  `)

  const louImage = getImage(allFile.edges[0].node)

  const pageMeta = {
    title: 'Home',
    type: 'home',
    location: location,
    image: null,
    url: `${location.href}`,
  }

  return (
    <Layout location={pageMeta.location} pageType={pageMeta.type}>
      <Row className="main-content">
        <Col
          className="intro-img"
          xs={{ size: 10, offset: 1 }}
          md={{ size: 5, offset: 1 }}
          lg={{ size: 5, offset: 1 }}
        >
          <GatsbyImage image={louImage} alt="Lou" />
        </Col>
        <Col
          className="intro-text"
          xs={{ size: 10, offset: 1 }}
          md={{ size: 5, offset: 0 }}
          lg={{ size: 5, offset: 0 }}
        >
          <div className="center">
            <h2>Hi, My name is Lou!</h2>
            <p>
              I'm a software engineer and tanguero in Minneapolis, Minnesota.
            </p>
            <p>
              This site hosts my tech portfolio, my blog, and information about
              Argentine tango instruction that I offer.
            </p>
            <Link to="/courses/" className="btn btn-primary cta">
              Go To TANGO COURSES
            </Link>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <SEO />
